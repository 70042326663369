<template>
	<SetupGlobal @pressed="getStarted" buttonText="LET'S GET STARTED">
		<Popup v-if="popupBoolean" :skipIntroScreen="true" @close="popupBoolean = false" @done="finished"
			introImage="https://ss3.nyc3.digitaloceanspaces.com/bh-dev/no-instance/splash_0c0ce23c45.png" color="blue"
			:step="steps[currentStep]" v-model="step" title="Headline goes here"
			text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula">
			<template v-slot:right>
				<component :is="'Step' + currentStep" :domain="domain" @next="next" @done="finished"
					@load="(is) => (loadPopup = is ? is : !loadPopup)" />
			</template>
		</Popup>
	</SetupGlobal>
</template>

<script>
import SetupGlobal from "bh-mod/components/layout/SetupGlobal";
import Popup from "bh-mod/components/layout/SetupGlobal/Popup";
import BhLoading from "bh-mod/components/common/Loading";
import Step0 from "./Step0";
import Step1 from "./Step1";
export default {
	components: { SetupGlobal, Popup, Step0, Step1, BhLoading },
	data() {
		return {
			showError: false,
			verifying: false,
			from: {
				name: "",
				address: "",
				city: "",
				region: "",
				country: "",
				postalCode: "",
			},
			sender: {
				name: "",
				email: "",
			},
			onBoarding: false,

			loadPopup: false,
			popupBoolean: false,
			currentStep: 0,
			step: 0,
			steps: [
				{
					title: "Enter your sending records",
					backButton: true,
				},
				{
					title: "Choose the address for all your Email broadcasts.",
					backButton: true,
				},
			],
			domain: {}
		};
	},
	watch: {
		step(val) {
			if (this.steps[val]) {
				this.currentStep = val;
			} else {
				this.finished();
			}
		},
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		theApp() {
			return this.$store.state.theApp;
		},
	},
	methods: {
		next(domain) {
			this.step++;
			if (domain) {
				this.domain = { ...domain }
			}
		},
		async finished(data) {
			this.sender = data;
			let { data: passed } = await this.$api.put(
				`/instances/${this.instance.id}`,
				this.from
			).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
			this.$store.commit("LOAD", true);
			this.$store.commit("GET_STARTED");
			this.$router.push("/");
			this.$api.post(`/settings/:instance/email/user`, {
				options: { seenIntro: true, email: [] },
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}).finally(() => {
				this.$store.commit("LOAD", false);
			});
		},
		getStarted() {
			this.popupBoolean = true;
		},
	},
	created() {
		let instance = this.instance;
		let {
			address = "",
			city = "",
			country = "",
			region = "",
			postalCode = "",
			broadcastEmail = "",
		} = instance;

		this.from.name = instance.name;
		this.from.address = address;
		this.from.city = city;
		this.from.region = region;
		this.from.country = country;
		this.from.postalCode = postalCode;
		this.from.broadcastEmail = broadcastEmail;
	},
};
</script>

<style lang="scss">
	.amenity-dialog {
		[role="tablist"] {
			display: none;
		}
	}

	.style-blue {

		.introPage-left,
		.ant-btn-primary {
			background-color: #2c94c8;
			border-color: #2c94c8;
		}
	}
</style>
