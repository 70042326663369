<template>
	<div>
		<h1>Add New Domain</h1>
		<a-form-model ref="ruleForm" :model="domain" :rules="rules">
			<a-row :gutter="16" class="mt-3" v-if="parentDomain.name">
				<a-col :span="12">
					<a-form-model-item ref="parentName" label="Parent Project Domain Name" prop="parentName">
						<a-input v-model="parentDomain.name" placeholder="Enter Parent Project Domain Name" size="large"
							:disabled="!!parentDomain.name">
							<a-icon v-if="parentDomain.name" slot="addonAfter" type="copy"
								@click="copy(parentDomain.name)" />
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<div v-if="parentDomain.verified && parentDomain.hasSSLCertificate"
						class="py-2 px-3 text-center step0-verified">
						Verified
					</div>
					<div v-else class="py-2 px-3 text-center step0-unverified">
						Unverified
					</div>
				</a-col>
			</a-row>
			<a-row :gutter="16" class="mt-3">
				<a-col :span="12">
					<a-form-model-item ref="name" prop="name">
						<template slot="label">
							Domain Name
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Recommended to use a subdomain instead of
									the root domain. Here’s an example:
									mail.example.ca
								</template>
								<a-icon type="question-circle" style="
                                        font-size: 14px;
                                        color: black;
                                        margin-left: 5px;
                                    " />
							</a-tooltip>
						</template>
						<a-input v-model="domain.name" placeholder="Enter Domain name" size="large"
							:disabled="domainCreated">
							<a-icon v-if="domainCreated" slot="addonAfter" type="copy" @click="copy(domain.name)" />
						</a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<div v-if="domain.verified && domain.hasSSLCertificate"
						class="py-2 px-3 text-center step0-verified">
						Verified
					</div>
					<div v-else class="py-2 px-3 text-center step0-unverified">
						Unverified
					</div>
				</a-col>
			</a-row>
		</a-form-model>

		<div v-if="domainCreated" class="mb-3 w-full">
			<template>
				<a-card class="mt-3">
					<template slot="title">
						<h4>Sending records</h4>
						<div>
							TXT records (known as SPF & DKIM) are <strong> required to send and receive
							</strong> email with
							Bildhive.
						</div>
					</template>

					<div>
						<h5>SPF</h5>
						<a-row :gutter="16" class="mt-3" v-if="DNS.SPF.name && DNS.SPF.value">
							<a-col :span="24" :md="10">
								<a-form-model-item label="Hostname">
									<a-input v-model="DNS.SPF.name" size="large" ref="spfName" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.SPF.name)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="10">
								<a-form-model-item label="Value">
									<a-input v-model="DNS.SPF.value" size="large" ref="spfValue" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.SPF.value)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="4">
								<div v-if="DNS.SPF.valid" class="py-2 px-3 text-center step0-verified">
									Verified
								</div>
								<div v-else class="py-2 px-3 text-center step0-unverified">
									Unverified
								</div>
							</a-col>
						</a-row>
					</div>
					<div class="mt-3">
						<h5>DKIM</h5>
						<a-row :gutter="16" class="mt-3" v-if="DNS.DKIM.name && DNS.DKIM.value">
							<a-col :span="24" :md="10">
								<a-form-model-item label="Hostname">
									<a-input v-model="DNS.DKIM.name" size="large" ref="dkimName" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.DKIM.name)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="10">
								<a-form-model-item label="Value">
									<a-input v-model="DNS.DKIM.value" size="large" ref="dkimValue" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.DKIM.value)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="4">
								<div v-if="DNS.DKIM.valid" class="py-2 px-3 text-center step0-verified">
									Verified
								</div>
								<div v-else class="py-2 px-3 text-center step0-unverified">
									Unverified
								</div>
							</a-col>
						</a-row>
					</div>
				</a-card>
			</template>

			<template>
				<a-card class="mt-5">
					<template slot="title">
						<h4>Receiving records</h4>
						<div style="white-space: normal">
							MX records are <strong>recommended for all domains, even if you are only sending
								messages</strong>. Unless you already have MX records for <strong> @{{
									domain.name
								}} </strong> pointing
							to another email provider (e.g. Gmail), you should update the following records.
						</div>
					</template>

					<template>
						<h5>MX</h5>
						<a-row :gutter="16" class="mt-3" v-if="DNS.MX[0]?.name && DNS.MX[0]?.value">
							<a-col :span="24" :md="10">
								<a-form-model-item label="Hostname">
									<a-input v-model="DNS.MX[0].name" size="large" ref="spfName" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.MX[0].name)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="10">
								<a-form-model-item label="Value">
									<a-input v-model="DNS.MX[0].value" size="large" ref="spfValue" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.MX[0].value)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="4">
								<div v-if="DNS.MX[0].valid" class="py-2 px-3 text-center step0-verified">
									Verified
								</div>
								<div v-else class="py-2 px-3 text-center step0-unverified">
									Unverified
								</div>
							</a-col>
						</a-row>
						<a-row :gutter="16" class="mt-3" v-if="DNS.MX[1]?.name && DNS.MX[1]?.value">
							<a-col :span="24" :md="10">
								<a-form-model-item label="Hostname">
									<a-input v-model="DNS.MX[1].name" size="large" ref="spfName" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.MX[1].name)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="10">
								<a-form-model-item label="Value">
									<a-input v-model="DNS.MX[1].value" size="large" ref="spfValue" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.MX[1].value)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="4">
								<div v-if="DNS.MX[1].valid" class="py-2 px-3 text-center step0-verified">
									Verified
								</div>
								<div v-else class="py-2 px-3 text-center step0-unverified">
									Unverified
								</div>
							</a-col>
						</a-row>
					</template>
				</a-card>
			</template>

			<template>
				<a-card class="mt-5">
					<template slot="title">
						<h4>Tracking records</h4>
						<div>
							The CNAME record is necessary for <strong>tracking opens, clicks, and
								unsubscribes.
							</strong>
						</div>
					</template>

					<template>
						<h5>CNAME</h5>
						<a-row :gutter="16" class="mt-3" v-if="DNS.CNAME.name && DNS.CNAME.value">
							<a-col :span="24" :md="10">
								<a-form-model-item label="Hostname">
									<a-input v-model="DNS.CNAME.name" size="large" ref="cnameName" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.CNAME.name)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="10">
								<a-form-model-item label="Value">
									<a-input v-model="DNS.CNAME.value" size="large" ref="cnameValue" disabled>
										<a-icon slot="addonAfter" type="copy" @click="copy(DNS.CNAME.value)" />
									</a-input>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :md="4">
								<div v-if="DNS.CNAME.valid" class="py-2 px-3 text-center step0-verified">
									Verified
								</div>
								<div v-else class="py-2 px-3 text-center step0-unverified">
									Unverified
								</div>
							</a-col>
						</a-row>
					</template>
				</a-card>
			</template>
		</div>

		<portal to="destination">
			<a-button v-if="!domainCreated || !domain.verified || !domain.hasSSLCertificate" size="large"
				class="text-black mx-4" type="link" @click="$emit('next')">SKIP</a-button>
			<a-button v-if="!domainCreated" size="large" type="primary" class="style-blue" @click="addDomain"
				:loading="loading">Add Domain</a-button>
			<a-button v-if="domainCreated && !domain.verified" size="large" type="primary" class="style-blue"
				@click="verifyDomain" :loading="loading">Verify</a-button>
			<a-button v-if="domainCreated && domain.verified && !domain.hasSSLCertificate" size="large" type="primary"
				class="style-blue" @click="generateSSL" :loading="loading">Generate SSL</a-button>
			<a-button v-else-if="domainCreated && domain.verified && domain.hasSSLCertificate" size="large"
				type="primary" class="style-blue" @click="$emit('next', domain)">Next</a-button>
		</portal>
	</div>
</template>

<script>
export default {
	props: {
		value: Number,
	},
	data: () => ({
		loading: false,
		domainCreated: false,
		DNS: {
			SPF: { name: "", value: "", valid: false },
			DKIM: { name: "", value: "", valid: false },
			MX: [{ name: "", value: "", valid: false }, { name: "", value: "", valid: false }],
			CNAME: { name: "", value: "", valid: false },
		},
		domain: { name: "", verified: false, hasSSLCertificate: false },
		parentDomain: { name: "", verified: false, hasSSLCertificate: false },
		rules: {
			name: [
				{
					required: true,
					message: "Please input a domain name",
					trigger: "blur",
				},
			],
		},
	}),
	computed: {
		instance() {
			return this.$store.state.instance;
		},
	},
	methods: {
		async addDomain() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					this.loading = true;
					try {
						await this.$api.post(
							`/sending-domains/${this.instance.id}`,
							{
								name: this.domain.name,
							}
						);
					} catch (error) {
						console.error("Error while adding new domain", error);
						this.$message.error(
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							"Error while adding new domain. Please try again!"
						);
					}
					this.loading = false;
				}
			});
		},

		async verifyDomain() {
			this.loading = true;
			try {
				const { data } = await this.$api.put(
					`/sending-domains/${this.instance.id}/verify`
				);
				if (data.isVerified) {
					this.$message.success("Domain verified successfully.");
				}
				this.$emit("next", data);
			} catch (error) {
				console.error("Error while verifying domain", error);
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while verifying domain. Please try again!"
				);
			}
			this.loading = false;
		},

		async generateSSL() {
			this.loading = true;
			try {
				const { data } = await this.$api.put(
					`/sending-domains/${this.instance.id}/request-ssl-certificate`
				);
				if (data.isVerified) {
					this.$message.success("SSL certificate generated successfully.");
				}
				this.$emit("next");
			} catch (error) {
				console.error("Error while generating SSL certificate", error);
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while generating SSL certificate. Please try again!"
				);
			}
			this.loading = false;
		},

		async fetchSendingDomain() {
			try {
				const { data } = await this.$api.get(
					`/sending-domains/${this.instance.id}`
				);
				this.setDomainDetails(data);
			} catch (error) {
				console.error("Error while fetching domain details", error);
			}
		},

		setDomainDetails(data) {
			if (data.length) {
				const childInstanceData = data.find((d) => !d.inherited);
				if (childInstanceData) {
					this.domainCreated = true;
					this.domain.name = childInstanceData.name;
					this.domain.verified = childInstanceData.isVerified;
					this.domain.hasSSLCertificate = childInstanceData.hasSSLCertificate;
					if (
						childInstanceData.providerData && childInstanceData.providerData.sending_dns_records
							.length > 2
					) {
						this.DNS.SPF.name =
							childInstanceData.providerData.sending_dns_records[0].name;
						this.DNS.SPF.value =
							childInstanceData.providerData.sending_dns_records[0].value;
						this.DNS.SPF.valid =
							childInstanceData.providerData
								.sending_dns_records[0].valid === "valid";
						this.DNS.DKIM.name =
							childInstanceData.providerData.sending_dns_records[1].name;
						this.DNS.DKIM.value =
							childInstanceData.providerData.sending_dns_records[1].value;
						this.DNS.DKIM.valid =
							childInstanceData.providerData
								.sending_dns_records[1].valid === "valid";
						this.DNS.CNAME.name =
							childInstanceData.providerData.sending_dns_records[2].name;
						this.DNS.CNAME.value =
							childInstanceData.providerData.sending_dns_records[2].value;
						this.DNS.CNAME.valid =
							childInstanceData.providerData
								.sending_dns_records[2].valid === "valid";
					}

					if (
						childInstanceData.providerData && childInstanceData.providerData.receiving_dns_records
							.length > 1
					) {
						this.DNS.MX[0].name = childInstanceData.providerData.domain.name;
						this.DNS.MX[0].value =
							childInstanceData.providerData.receiving_dns_records[0].value;
						this.DNS.MX[0].valid =
							childInstanceData.providerData
								.receiving_dns_records[0].valid === "valid";

						this.DNS.MX[1].name = childInstanceData.providerData.domain.name;
						this.DNS.MX[1].value =
							childInstanceData.providerData.receiving_dns_records[1].value;
						this.DNS.MX[1].valid =
							childInstanceData.providerData
								.receiving_dns_records[1].valid === "valid";
					}
				}

				const parentInstanceData = data.find((d) => d.inherited);
				if (parentInstanceData) {
					this.parentDomain.name = parentInstanceData.name;
					this.parentDomain.verified = parentInstanceData.isVerified;
					this.parentDomain.hasSSLCertificate = parentInstanceData.hasSSLCertificate;
				}
			}
		},

		copy(value) {
			navigator.clipboard.writeText(value);
			this.$message.success("Copied");
		},
	},
	created() {
		this.fetchSendingDomain();
	},
};
</script>

<style>
	.step0-verified {
		color: #fff;
		border-radius: 4px;
		width: fit-content;
		margin-top: 30px;
		background-color: #28c791;
	}

	.step0-unverified {
		color: #fff;
		border-radius: 4px;
		width: fit-content;
		margin-top: 30px;
		background-color: #f86469;
	}

	.splash-screen .ant-card-body {
		display: block !important;
	}
</style>
