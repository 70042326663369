<template>
	<div>
		<h1>Email Verification</h1>
		<p class="mb-3">
			Specify the sender name and Email address that will be used to send
			your broadcast.
		</p>
		<a-row :gutter="16">
			<a-form-model ref="ruleForm" :model="sender" :rules="rules">
				<a-col :span="12">
					<a-form-model-item ref="name" label="Name" prop="name">
						<a-input v-model="sender.name" placeholder="Enter sender name" size="large" />
					</a-form-model-item>
				</a-col>

				<a-col :span="12">
					<a-form-model-item ref="email" label="Email" prop="email">
						<a-input v-model="sender.email" placeholder="Enter sender email" size="large" />
					</a-form-model-item>
				</a-col>
			</a-form-model>
		</a-row>

		<a-alert type="info" show-icon style="width: fit-content;" v-if="domain && domain.name && domain.verified">
			<template slot="message">
				You can use any username for your verified domain for example <strong>
					marketing@{{ domain.name }}, sales@{{ domain.name }}</strong>
			</template>
		</a-alert>

		<portal to="destination">
			<a-button size="large" class="text-black mx-4" type="link" @click="$emit('done')">SKIP</a-button>
			<a-button size="large" type="primary" class="style-blue" @click="next">DONE</a-button>
		</portal>
	</div>
</template>

<script>
import { validateEmail } from "bh-mod";

export default {
	props: {
		value: Number,
		domain: Object
	},
	data: () => ({
		currentStep: 0,

		showError: false,
		verifying: false,
		from: {
			name: "",
			address: "",
			city: "",
			region: "",
			country: "",
			postalCode: "",
		},
		sender: {
			name: "",
			email: "",
		},
		rules: {
			name: [
				{
					required: true,
					message: "Please input a sender name",
					trigger: "blur",
				},
			],
			email: [
				{
					required: true,
					message: "Please input a sender email",
					trigger: "blur",
				},
			],
		},
		onBoarding: false,
	}),
	watch: {
		currentStep(val) {
			return this.$emit("input", val);
		},
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		theApp() {
			return this.$store.state.theApp;
		},
	},
	methods: {
		async verify() {
			this.showError = false;
			if (!this.sender.name.trim())
				return (
					this.$message.error(
						"Sender name is required. Please try again."
					),
					(this.showError = true)
				);
			if (!validateEmail(this.sender.email))
				return (
					this.$message.error(
						"Invalid Email input. Please try again."
					),
					(this.showError = true)
				);
			this.loadModal = true;
			this.$api
				.post(`/senders/${this.instance.id}`, {
					name: this.sender.name,
					email: this.sender.email,
				})
				.then(({ data }) => {
					this.loadModal = false;
					this.verifying = true;
					this.$store.state.email.senders[data.id] = data;
					this.$message.success(
						data.active
							? "Great your email has been verified."
							: "Great! You've been sent an email for verification."
					);
					this.$emit("done", this.sender)
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
		},
		validateEmail,
		skip() {
			return this.$emit("next");
		},
		next() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.verify();
					setTimeout(() => {
						if (this.verifying) {
							return this.$emit("done", this.sender);
						}
					}, 1000);
				} else {
					return false;
				}
			});
		},
	},
};
</script>

<style></style>
